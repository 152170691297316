.quarter-view-panel {
  border-right: 2px solid rgb(215, 215, 215);
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    margin: 5px;
  }

  p {
    font-size: small;
    white-space: nowrap;

    &:last-child {
      margin: 5px;
    }
  }

  &:last-child {
    border: none;
  }
}
