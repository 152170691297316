.date-btn-group {
  margin: 1em 0;
}

.generate-button {
  display: block;
  margin-top: 1em;
}

.selector {
  margin: 1em 0;
}
