.account-filter {
  .custom-checkbox {
    width: 20px !important;
    height: 20px !important;
    margin: 0 10px 0 0 !important;
  }

  .styled-checkbox {
    margin-bottom: 0.4em;
  }

  label {
    font-family: "FS Elliot Web Regular", "Arial", arial, sans-serif;
    margin: 0;

    &.bold {
      font-family: "FS Elliot Web Bold", "FS Elliot Web Regular", "Arial", arial,
        sans-serif;
    }
  }

  .pad-left {
    padding-left: 45px;
  }

  .display-flex {
    display: flex;
    align-items: center;
  }

  .account-selection {
    margin-bottom: 5px;
  }
}
