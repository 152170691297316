.onboarding-dashboard-links > li > a {
  display: block;
  position: relative;
  padding: 15px 26px 15px 5px;
}

.onboarding-dashboard-links > li {
  list-style-type: none;
  border-bottom: 1px solid #c7c7c7;
}

.onboarding-dashboard-links > li > a:after {
  content: "\F105";
  font-family: fontawesome;
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
