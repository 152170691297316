#dashboard-tabs {
  .dashboard-controls {
    display: flex;
    justify-content: space-between;
  }

  .time-series-chart-wrapper {
    margin-bottom: 0;
  }

  .about-this-chart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
