.heading {
  padding-left: 2%;
  padding-top: 1%;
  font-weight: bold;
  font-size: 500%;
}

.history-header {
  background-color: white;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 8px 8px 0 0;
  padding: 1.9%;
}

.event-row {
  display: flex;
  align-items: flex-start;
}

.bold-text {
  font-weight: bold;
  margin-bottom: 1%;
}

.confirmation-message {
  flex: 1;
  margin: 0 10px;
  font-size: 100%;
}

.button-text {
  font-weight: 1000;
  font-size: 160%;
  white-space: nowrap;
  display: inline-block;
}

.button-text-current {
  font-weight: 1000;
  font-size: 160%;
  display: inline-block;
  white-space: nowrap;
}

.view-confirmation-button {
  margin-left: 10px;
  display: inline-block;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.event-separator {
  border: none;
  border-top: 1px solid lightgray;
  margin-top: 3%;
  margin-left: 4%;
  margin-right: 6%;
}

.event-separator-current {
  border: none;
  border-top: 1px solid lightgray;
  margin-right: 2.5%;
  margin-left: 1%;
  margin-top: 3%;
}

.event-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.enrolled-box {
  border-radius: 10px;
  background-color: #d2f1fd;
  margin-left: 2%;
  padding: 2%;
  margin-right: 2%;
  border: 1px solid lightgray;
  margin-bottom: 1%;
}

.event-effective-year {
  font-size: 130%;
  font-weight: bold;
  padding-left: 4%;
}

.event-effective-year-current-enrollment {
  font-size: 130%;
  font-weight: bold;
  padding-left: 1%;
}

.card-format {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #ccc;
}

.header-format {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 8px 8px 8px;
}

.event-history-heading {
  font-size: 140%;
  padding: 2%;
  margin-left: 0.3%;
  margin-top: -1%;
  font-weight: lighter;
}

.filter-button-position {
  margin-left: 60%;
  margin-top: -6.5%;
  padding-left: 1%;
}

.currently-enrolled {
  font-size: 130%;
  margin-left: 1%;
  margin-bottom: -2%;
}

.button-placement {
  margin-top: -5%;
  margin-left: 71%;
}

.button-placement-did-not-want-to-participate {
  margin-top: -5%;
  margin-left: 67%;
}

.button-placement-no-action {
  margin-top: -5%;
  margin-left: 73%;
}
