.modal-backdrop {
  opacity: 0.5 !important;
}

.left-align {
  text-align: left;
}

.beneficiary-modal-button {
  .btn-link {
    padding-left: 0;
  }
}

.button-text-wrap {
  max-width: 100%;
  overflow-wrap: break-word;
}

.whitespace-no-wrap {
  white-space: nowrap;
}

.beneficiaries-modal {
  .modal-content {
    padding: 10px;
  }

  .modal-header {
    h2 {
      display: inline-block;
    }
  }

  .modal-xl {
    max-width: 1200px;
  }

  .modal-bene-type {
    padding-right: 15px;
  }

  .modal-form {
    border-left: 4px dotted #e6e6e6;
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
  }
}
