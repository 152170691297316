.personal-details {
  table > thead > tr > th {
    &:first-child {
      width: 43%;
    }

    h3 {
      margin-bottom: 0;
    }
  }

  .pds-segmentedControl {
    background: #e8e8e8;
    border-radius: 20px;
    position: relative;
    display: table;
    height: 34px;
    line-height: 1.75;
  }

  .pds-segmentedControl:focus-within {
    box-shadow: 0 0 5px #0061a0;
  }

  .pds-segmentedControl input {
    position: absolute;
    opacity: 0;
  }

  .pds-segmentedControl input + label {
    position: relative;
    color: #0061a0;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    font-family: "FS Elliot Web Bold", "FS Elliot Web Regular", Arial, arial,
      sans-serif;
    z-index: 2;
    transition: color 0.5s cubic-bezier(0.8, 0, 0.33, 1);
  }

  .pds-segmentedControl input:checked + label {
    z-index: 3;
    color: #002e6d;
  }

  .pds-segmentedControl :last-child:before,
  .pds-segmentedControl input + label {
    margin: 3px;
    padding: 6px 24px;
    min-width: 106px;
    height: calc(34px - 6px);
    display: table-cell;
    width: 50%;
  }

  .pds-segmentedControl :last-child:before {
    content: "";
    background: #fff;
    border-radius: 17px;
    color: #0061a0;
    box-shadow: 0 0 8px #c5c6c5;
    z-index: -1;
    position: absolute;
    margin-top: -2px;
    margin-left: -21px;
    width: 100%;
  }

  .pds-segmentedControl.pds-segmentedControl-triple input + label {
    width: 33.33%;
  }

  .pds-segmentedControl.pds-segmentedControl-triple
    :nth-child(1):checked
    ~ :last-child:before {
    transform: translateX(-200%);
    transition: transform 0.5s cubic-bezier(0.05, 0, 0.18, 1);
  }

  .pds-segmentedControl.pds-segmentedControl-triple
    :nth-child(3):checked
    ~ :last-child:before {
    transform: translateX(-100%) translateX(-3px);
    transition: transform 0.5s cubic-bezier(0.05, 0, 0.18, 1);
  }

  .pds-segmentedControl.pds-segmentedControl-triple
    :nth-child(5):checked
    ~ :last-child:before {
    transform: translateX(-6px);
    transition: transform 0.5s cubic-bezier(0.05, 0, 0.18, 1);
  }

  .pds-segmentedControl.pds-segmentedControl-disabled {
    background: #f6f6f8;
  }

  .pds-segmentedControl.pds-segmentedControl-disabled :last-child:before {
    background: #fff;
    box-shadow: 0 0 8px #e8e8e8;
  }

  .pds-segmentedControl.pds-segmentedControl-disabled input + label {
    color: #9d9ea4;
    cursor: not-allowed;
  }

  .pds-segmentedControl.pds-segmentedControl-small {
    height: 26px;
    line-height: 24px;
  }

  .pds-segmentedControl.pds-segmentedControl-small input + label {
    font-size: 12px;
  }

  .pds-segmentedControl.pds-segmentedControl-small :last-child:before {
    margin: 2px;
  }

  .pds-segmentedControl.pds-segmentedControl-small :last-child:before,
  .pds-segmentedControl.pds-segmentedControl-small input + label {
    padding: 1px;
    height: calc(26px - 6px);
  }

  .pds-segmentedControl :nth-child(1):checked ~ :last-child:before {
    transform: translateX(-100%);
    transition: transform 0.5s cubic-bezier(0.05, 0, 0.18, 1);
  }

  .pds-segmentedControl :nth-child(3):checked ~ :last-child:before {
    transform: translateX(-6px);
    transition: transform 0.5s cubic-bezier(0.05, 0, 0.18, 1);
  }

  .form-group {
    margin-bottom: 0;
    display: flex;
    align-items: center;

    input,
    select {
      width: 50%;
    }
  }

  .error-message {
    margin-left: 10px;
  }
}
