@import "../../../scss/colors";

.manage-contributions-calculate {
  .manage-contributions-spacing {
    margin-bottom: 15px;
  }

  .blue-box {
    background-color: $blue-background;
    width: 100%;
    padding: 10px;
    display: inline-block;
  }

  .review-button {
    padding: 1em;
    display: flex;
    justify-content: center;
  }

  .effective-date {
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  .individual-form-output {
    margin-bottom: 15px;
  }
}
