.manage-contributions-review {
  display: flex;

  .updated-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contributions {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .actions-row {
    margin-top: 1em;
    margin-bottom: 36px;
  }

  .contribution {
    font-size: 1em;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .contributionName {
    max-width: 75%;
    margin-right: 0.5em;
  }

  .contributionValue {
    text-align: end;
  }
}
