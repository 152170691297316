.list-bullet-active {
  float: left;
  margin-right: 10px;
  background: #0091da;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 100px;
  font-weight: 700;
  color: white;
  text-align: center;
}

.distribution-method {
  margin-left: 40px;
}
