.svg-pipes {
  background: #bddef0 url("./caleb-the-plumber.svg") no-repeat bottom center;
  background-size: auto 530px;
  min-height: 500px;
  overflow: hidden;
}

.svg-page {
  flex: 1 1 auto;
}
