.dashboard-contribution-panel {
  .effective-date {
    text-align: center;
    margin-top: 1em;
  }

  .view-contributions {
    margin-top: 1em;
  }
}
