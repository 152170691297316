@import "../../../scss/colors";

.article-background {
  background-color: white;
  width: 90%;
  padding: 5%;
  flex-wrap: wrap;
  border-radius: 5px;
  margin: 5%;
}

.article-header {
  color: #4b4687;
  font-size: 25px;
}

.article-type {
  color: black;
  padding: 4px;
  margin-left: -4px;
}

.article-filter {
  background-color: #4b4687;
  color: white;
  border-radius: 5px;
  padding: 0.5% 2%;
}

.article-description {
  color: black;
}

.article-button-link {
  color: $principal-blue;
  background-color: white;
  border-color: $principal-blue;
  border-radius: 25px;
  margin: 0 auto;
  display: block;
  padding: 10px;
  margin-top: 10px;
}
