@import "../../../scss/colors";

.manage-contributions {
  .manage-contributions-layout {
    width: 100%;
    display: flex;
    font-size: 16px;
    font-weight: normal;
  }

  form {
    display: inherit;
    width: 100%;
  }

  .form-group {
    max-width: 275px;
    margin: 0;
  }

  .manage-contribution-inputs {
    display: inline;
    width: 50%;
    min-width: 50%;
    padding-right: 10px;
  }

  .salary-form {
    display: flex;
    margin-bottom: 1em;

    .form-group {
      margin-right: 1em;
    }

    .frequency-selector {
      width: 210px;
      margin: 0;
    }
  }

  .more-info-modal {
    margin: 0;
    padding: 0;
  }

  .contribution-interaction {
    .user-contribution-input {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .manage-contributions-text {
    font-size: 16px;
    margin-top: 15px;
    font-weight: lighter;
    font-style: italic;
  }

  .manage-contributions-summary {
    font-weight: bold;
    padding-top: 2.5px;
    padding-bottom: 2.5px;
  }

  .manage-contributions-spacing {
    margin-bottom: 15px;
  }

  .type-buttons {
    margin-bottom: 10px;
  }

  .btn-group {
    min-width: 150px;
    max-width: 156px;
    min-height: 70px;
    max-height: 70px;
    padding-top: 28px;

    .btn {
      border: 1px solid $light-grey;
    }

    :not(.btn-primary) {
      background-color: #ffffff;
    }
  }

  .updated-contributions-success {
    margin-bottom: 1em;
  }

  .frequency-selector {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .submit-btn {
    float: right;
  }

  .test {
    display: block;
  }

  .bold {
    font-weight: bold;
  }

  .italic {
    font-style: italic;
  }

  .error-message {
    color: red;
  }

  .frequency-dropdown__icon {
    float: right;
    margin: 5px;
    border: solid black;
    border-width: 0 3px 3px 0;
    padding: 3px;
  }

  .frequency-dropdown__icon.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .frequency-dropdown__icon.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .hide {
    display: none;
  }

  .show {
    display: block;
  }
}
