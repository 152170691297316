.statement-print-header {
  position: relative;

  span {
    display: block;
  }

  .principal-info-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .statement-date-block {
    img {
      display: block;
      margin: 0 0 0 auto;
      width: 150px;
    }
  }
}
