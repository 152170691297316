@import "src/scss/colors";

.account-display {
  h2 {
    margin-bottom: 0;
  }

  .account409info {
    display: block;
  }

  .date-display {
    display: block;
    margin-bottom: 1em;
  }
}

.statement-overflow {
  overflow-x: scroll;
}
