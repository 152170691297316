.scheduled-distributions-timeline {
  margin-top: 2em;

  .timeline-wrapper {
    padding: 0;
  }

  .header {
    margin-bottom: 0;
  }

  svg {
    height: 240px;
  }
}
