.custom-table-wrapper {
  table {
    border: none;
    border-spacing: 0;
    position: relative;
    width: 100%;

    th {
      background: white;
      position: sticky;
      top: 0px;
      border: none;
      font-weight: normal;
      padding: 0.5rem;
      vertical-align: bottom;
      z-index: 100;

      .sort-group {
        display: flex;

        .header {
          flex: 1 1 auto;
        }

        .sort-icon {
          align-items: center;
          display: flex;
          justify-content: center;
          margin-left: 5px;
        }
      }

      .col-group {
        font-size: 0.8em;
        font-weight: bold;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        height: 100%;
        white-space: nowrap;
      }

      .header {
        font-weight: bold;
      }

      .group-header {
        font-size: 1.3em;
        text-align: center;
      }

      .bold {
        font-weight: bold;
      }
    }

    .advisor-name {
      font-style: italic;
    }

    .color-cell {
      padding: 0;

      div {
        height: 100%;
        width: 100%;
        min-width: 1em;
      }
    }

    .sticky-table {
      position: sticky;
      top: 0;
    }

    td {
      padding: 0.5rem;
      border: none;
      font-weight: normal;
      white-space: pre-wrap;
    }

    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
    }

    tfoot {
      td {
        div {
          margin: 0.25em 0;
          font-weight: bold;
        }
      }
    }
  }

  span.block {
    display: block;
  }

  .span-links {
    display: inline;
  }

  .inner-table {
    margin-left: 1em;
  }

  .right {
    text-align: right;
  }

  .bold {
    font-weight: bold;
  }

  .left-align {
    text-align: left;
  }

  .right-align {
    text-align: right;
  }

  .center-align {
    text-align: center;
  }

  .text {
    text-align: left;
  }

  .top-align {
    vertical-align: top;
  }

  .icon-cell {
    width: 1em;
    color: #035fa4;
  }

  .year-row {
    font-size: 1.2em;
  }

  .year-cell {
    margin-right: 0.3em;
  }

  .number {
    text-align: right;
  }

  .no-wrap {
    white-space: nowrap;
  }
}

.grid-table-wrapper {
  thead,
  tbody {
    border: 1px solid #dbdbdb;

    th,
    td {
      padding: 1em !important;
      border-right: 1px solid #dbdbdb !important;
    }

    td {
      vertical-align: top;
    }
  }

  .row-cell {
    margin-bottom: 1em;
  }
}

.table-account {
  width: 100%;
}

.clickable-cell {
  padding: 16px 0px 16px 0px !important;
  cursor: pointer;
}

.prr-faq-link {
  display: inline;
  margin-left: 0.25em;
}

@media only screen and (max-width: 766px) {
  .prr-faq-link {
    position: absolute;
    left: 25%;
    top: 57%;
    padding: 10px;
  }
}

.non-clickable-cell {
  padding: 16px 0px 16px 0px !important;
}

.clickable-cell:hover {
  background-color: #f1f1f1;
  transition: background-color 150ms ease-in-out;
}

.clickable-cell div {
  display: table-cell;
}

.clickable-cell i {
  margin-top: -5px;
}

.clickable-cell:active {
  background-color: #e5f4fb;
}
