.dashboard-account {
  label {
    margin: 0;
  }
}

.dashboard-asset-class-chart {
  display: block;
  height: 400px;
}
