.totals-in-this-period-table {
  thead {
    display: none;
  }

  tfoot {
    display: none;
  }

  tr:last-child {
    border: none;
  }
}
