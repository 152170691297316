.yearly-new-enrollment {
  text-align: left;
  color: #035fa4;
  font-size: 150%;
  margin-top: -1%;
  font-weight: bold;
}

.custom-button {
  font-size: 150%;
  font-weight: bold;
}

.raised-padding1 {
  margin-top: 1.8%;
}

.raised-padding2 {
  padding-top: -500%;
}

.raised-enroll-now {
  position: relative;
}
.color {
  color: #0091da;
}
