@import "../../scss/colors";

.statements {
  .visualization-panel-wrapper {
    padding: 0;
  }

  .panel {
    margin-bottom: 1.5em;
  }
}

@media print {
  .custom-table-wrapper table {
    table-layout: fixed;
  }
  .custom-table-wrapper th {
    font-size: 0.7em;
  }

  .custom-table-wrapper td {
    font-size: 0.7em;
  }
}

.statements {
  .print-only {
    display: none;
    @media print {
      display: block;
    }
  }

  section {
    margin-bottom: 1.5em;
  }

  h1 {
    margin: 0;
  }

  .recharts-wrapper {
    svg {
      @media print {
        width: 75% !important;
        //max-width: 50%;
      }
    }
  }
}
