@import "src/scss/colors";

.modal-content {
  padding: 10px;
}

.modal-header {
  h2 {
    display: inline-block;
  }
}

.modal-link {
  align-self: center;
  border: none;
  background: none;
  color: $principal-dark-blue;
  cursor: pointer;
  display: inline-block;
  flex-basis: 100%;
  font-family: "FS Elliot Web Bold", "FS Elliot Web Regular", "Arial", arial,
    sans-serif;
  margin-left: 0.25em;

  &:hover {
    text-decoration: underline;
  }
}

.modal-link-icon {
  text-decoration: none;
}

.icon-blue {
  color: $principal-dark-blue;
}

.modal-xl {
  max-width: 1200px;
}

.modal-bene-type {
  padding-right: 15px;
}

.modal-form {
  border-left: 4px dotted $light-grey;
}
