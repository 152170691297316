.input-suffix {
  text-align: center;
}

.bene-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.beneficiary-input {
  padding-left: 0;
  padding-right: 0;
}

.bene-details-field {
  display: flex;
  justify-content: space-between;
}

.bene-accordion-header {
  font-size: smaller;
  white-space: nowrap;
}
