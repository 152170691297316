@import "../../../scss/colors";

.fwa-title {
  font-size: 30px;
  color: white;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.fwa-text {
  font-size: 15px;
  color: white;
  margin-left: 20px;
}

.fwa-table-layout {
  margin-top: 20px;
  margin-left: 20px;
  font-size: 15px;
  display: grid;
  color: white;
  grid-template-columns: 13% 29% 29% 29%;
}

.fwa-button {
  font-size: 15px;
  color: white;
  background-color: transparent;
  text-align: left;
  padding: 10px 15px;
  margin: 0px 0px 11px;
  border-color: #035fa4;
  box-shadow: #035fa4;
  border-style: hidden;
  border-radius: 5px;
  width: 95%;
}

.fwa-button-active {
  background-color: white;
  color: #035fa4;
  font-weight: bold;
}

.dropdown-button {
  color: white;
  background-color: transparent;
  font-size: 25px;
  border: none;
  vertical-align: initial;
}

.div-styling {
  text-align: center;
  padding-top: 60px;
}

.txt:hover {
  text-decoration: underline;
}

.fwa-background {
  background: linear-gradient(322deg, #0091da 0%, #035fa4 100%) !important;
  padding: 60px 11%;
}

@media (min-width: 760px) {
  .masonry {
    column-count: 3;
  }
}

.article-card {
  display: inline-block;
}
