.asset-class-modal {
  .asset-class-row {
    display: table;
    height: 100%;
    margin-bottom: 2px;
    border-width: 16px;
    padding-left: 16px;

    h3 {
      margin-top: 0;
      margin-bottom: 4px;
    }
  }

  .asset-class-color {
    width: 16px !important;
    height: 100%;
    display: table-cell;
  }
}
