.filter-button {
  background-color: white;
  border-radius: 50px;
  height: 70%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.round-button-container {
  display: inline-block;
  position: relative;
}

.button-group {
  display: flex;
  background-color: lightgray;
  border-radius: 10px;
  padding: 5px;
}

.round-button {
  background-color: transparent;
  border-radius: 50%;
  color: white;
  padding: 10px 20px;
  transition: background-color 0.3s;
  margin: 5px;
  cursor: pointer;
  border: none;
}

.round-button:hover {
  background-color: lightgray;
}

.round-button.selected {
  background-color: white !important;
  color: black;
  padding: 10px 20px;
}

.top-edge,
.bottom-edge {
  background-color: gray;
  height: 10px;
  border-radius: 5px;
}

.top-edge {
  top: -5px;
  width: 100%;
}

.bottom-edge {
  bottom: -5px;
  width: 100%;
}

.transparent-button {
  padding: 10px;
  margin-right: 10px;
  border: 2px solid transparent;
  background-color: transparent;
  border-radius: 50px;
  color: #000;
}

.transparent-button.clicked {
  background-color: white;
  color: #fff;
}

.word-color {
  color: #004c97;
}

.word-sizing {
  font-size: 150%;
  font-weight: 1000;
  display: inline-block;
  white-space: nowrap;
}
