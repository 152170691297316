.display-flex {
  display: flex;
  align-items: baseline;
}

.checkbox {
  margin-left: 8px;
}

.fa {
  margin-right: 8px;
}

.pending-btn {
  padding: 4px 10px 2px 10px !important;
}

.margin-20 {
  margin-top: 20px;
}
