.beneficiaries {
  .update-allocations-button {
    margin-right: 10px;
  }

  .beneficiaries-display {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .bene-heading-display {
    display: flex;
    justify-content: space-between;
  }

  .cancel-button {
    margin: 0 10px;
  }

  .update-button {
    margin-left: 10px;
  }

  .bene-modal {
    display: flex;
  }

  .panel-add-button {
    align-items: center;
    display: flex;
  }

  .beneficiaries-panel {
    background-color: #f1f1f1;
    border: 1px solid #c7c7c7;
    margin-top: 1em;

    input[type="number"].without_arrows::-webkit-inner-spin-button,
    input[type="number"].without_arrows::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* for mozilla */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    .panel-heading {
      background-color: inherit;
      border-color: #e6e6e6;
    }
  }

  .small-color {
    color: #767676;
  }

  .my-text {
    color: #035fa4;
    font-size: 1.72856em;
    font-weight: bold;
  }

  .virtual-coach-text {
    color: darkslateblue;
    font-size: 1.72856em;
    font-weight: bold;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .review-btn-display {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 1em;
  }

  .panel-wrapper {
    flex: 1;

    &:first-child {
      padding-right: 0.5em;
    }

    &:not(:first-child) {
      padding-left: 0.5em;
    }
  }

  label.radio-label {
    align-items: center;
    display: flex;
    margin-bottom: 0.5em;

    input {
      height: 1.05em;
      margin: 0;
      width: 1.05em;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
