.optional {
  font-weight: bold;
  color: #767676;
}

.form-group {
  input {
    padding-left: 20px;
  }

  .prefix {
    padding: 12px 10px 8px 10px;
  }

  .suffix {
    padding: 10px 5px 10px 15px;
  }
}
