.contributions-summary {
  .top {
    display: flex;

    .deferral-elections {
      display: inline;
      width: 80%;
      margin-bottom: 4em;
    }

    .resources-links {
      display: inline;
      align-content: flex-end;
      width: 20%;
      margin-top: 11px;
      margin-left: 1em;
      padding: 1em;
      height: fit-content;
    }

    .center-align {
      text-align: center;
    }
  }

  .contribution-amounts {
    margin-bottom: 5em;

    .contribution-info {
      margin-top: 5px;
    }
  }

  .contribution-sources {
    margin-bottom: 1em;
  }

  .nav-tabs {
    border-bottom: 1px solid #c7c7c7;

    > a {
      margin-right: 2px;
      line-height: 1.428571429;
      border: 1px solid transparent;
      border-radius: 4px 4px 0 0;
      background-color: #f1f1f1;
      border-bottom-color: #c7c7c7;
      color: #464646;
      padding-top: 5px;
      float: left;
      margin-bottom: -1px;
      position: relative;
      display: block;

      &:hover,
      &:focus {
        background-color: #e6e6e6;
        border-width: 1px;
        border-style: solid;
        border-color: transparent transparent #c7c7c7;
      }

      &.active {
        color: #464646;
        background-color: #fff;
        border: 1px solid #c7c7c7;
        border-bottom-color: transparent;
        cursor: default;

        &:hover,
        &:focus {
          color: #464646;
          background-color: #fff;
          border: 1px solid #c7c7c7;
          border-bottom-color: transparent;
          cursor: default;
        }
      }
    }
  }

  .nav {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    > a {
      position: relative;
      display: block;
      padding: 10px 15px;
    }
  }
}
