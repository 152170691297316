html {
  height: 100%;
  font-size: 16px !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  #pds-global-header {
    font-size: 16px !important;
  }

  .buorg {
    background-color: #162b48;
    color: #ffffff;
  }

  #buorgul {
    display: none;
  }

  .buorg-fadeout {
    display: none;
  }

  body {
    height: 100%;

    #root {
      display: flex;
      flex-direction: column;
      min-height: 100%;

      .content {
        flex: 1 1 auto;
        margin-bottom: 40px;
        padding-bottom: 20px;
        padding-top: 20px;
      }

      a {
        cursor: pointer;
      }

      .panel {
        border-radius: 6px !important;

        .panel-heading {
          border-top-left-radius: 6px !important;
          border-top-right-radius: 6px !important;
        }

        .panel-footer {
          border-bottom-left-radius: 6px !important;
          border-bottom-right-radius: 6px !important;
        }
      }
    }
  }
}

.util-margin-bottom-5 {
  margin-bottom: 5px;
}

.util-padding-left-20 {
  padding-left: 20px;
}
