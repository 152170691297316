.collapse-enrollment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.collapse-text {
  text-align: center;
  margin: 0 auto;
}

.show-more-less {
  display: inline-block;
  text-align: center;
}
