.links {
  font-size: 14px;
}

.header-text {
  text-align: center;
  color: black;
}

.div-style {
  padding-left: 15px;
}

.card-header-text {
  text-align: center;
  color: #4b4687;
  font-size: 21px;
}

.column {
  float: left;
  width: 18.9%;
  margin-left: 10px;
  margin-bottom: 50px;
  background-color: #e8f4fc;
}

.button-style {
  color: white;
  background-color: #035fa4;
  border-radius: 25px;
  display: block;
}
