.orange-rectangle {
  display: flex;
  max-width: 600px;
  width: 100%;
  height: 30px;
  border: 2px solid #ff7518;
  border-left-width: 6px;
  text-align: left;
  padding: 10px;
  border-radius: 8px;
}

.text {
  font-size: 13px;
  margin-top: -4px;
  padding-left: 10px;
  font-weight: bold;
  white-space: nowrap;
}

.close-icon {
  cursor: pointer;
  font-size: 12px;
  padding-left: 35%;
  font-weight: bold;
  margin-top: -0.75%;
}

.circle {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  border: 1px solid black;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  font-weight: normal;
}
