.investment-allocations {
  .no-investments-message {
    margin-top: 14px;
    margin-bottom: 24px;
  }

  .account-name-header {
    font-size: 1.3em;
    text-align: left;
  }
}
