.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: #024272;
    outline: 0;
  }

  font-family: "FS Elliot Web Bold", "FS Elliot Web Regular", "Arial", arial,
    sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #035fa4;
  text-decoration: none;
}
