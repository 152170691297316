.statements-quick-view {
  background: #f1f1f1 0 0 no-repeat padding-box;
  border: 1px solid #c8c8c8;
  border-radius: 2px;
  padding: 15px;
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 1em;
}

.statements-right-content {
  margin-left: 20px;
  flex-grow: 1;

  .row {
    margin-top: 25px;
  }
}
table.customTable {
  width: 100%;
  background-color: #ffffff;
  border-collapse: collapse;
  /*border-width: 2px;
  border-color: #f8f7f6;
  border-style: solid;*/
  color: #000000;
  text-align: center;
}

table.customTable td,
table.customTable th {
  text-align: center;
  border-width: 2px;
  border-color: transparent; /* #f2f2f2;*/
  border-style: solid;
  padding: 5px;
}

table.customTable tr {
  border-color: #f2f2f2;
  border-style: solid;
}

table.customTable tbody:nth-child(even) {
  background-color: #f2f2f2;
}

table.customTable thead {
  background-color: #caebfa;
  border-style: none;
}
