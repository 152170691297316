.status-table td {
  width: 0.1%;
  white-space: nowrap;
}

.status-table {
  table-layout: fixed;
  max-width: 1000px;
}

.status-box {
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  width: fit-content;
}

.bg-green {
  background-color: darkgreen;
}

.bg-red {
  background-color: darkred;
}
