$blue: #035fa4;
$purple: #4b4687;

.scheduled-distribution-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1em;
  min-height: 12em;
  margin-bottom: 2em;
  margin-top: 0.5em;

  .account-type {
    border: 2px solid $blue;
    background-color: $blue;
    color: white;
    margin: 0;
    text-align: center;
    font-weight: bold;

    &.primary {
      border: 2px solid $blue;
      background-color: $blue;
    }

    &.alt-color {
      border: 2px solid $purple;
      background-color: $purple;
    }
  }

  .scheduled-distribution-card {
    margin-bottom: 1em;

    .account-name {
      margin-top: 0;
      margin-bottom: 1em;
      color: $blue;
    }

    table {
      width: 100%;

      td {
        line-height: 2em;
      }
    }

    .card-container {
      padding: 1em;
    }

    .link {
      justify-self: center;
      display: inline-block;
      vertical-align: center;
      align-self: center;
    }

    &.primary {
      border: 2px solid $blue !important;
      border-left: 2px solid $blue !important;
    }

    &.alt-color {
      border: 2px solid $purple;
      border-left: 2px solid $purple;

      h4 {
        color: $purple;
      }
    }
  }
}

// This is for IE specific styles
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .scheduled-distribution-cards {
    display: flex;
    min-height: 10em;
    margin-bottom: 2em;
    margin-top: 0.5em;
    justify-content: space-between;

    .scheduled-distribution-card {
      padding: 1em;
      display: inline-block;
      flex-direction: column;
      width: auto;
      height: 8em;
      margin-bottom: 1em;

      .card-content {
        display: flex;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 40px;

        .labels {
          display: flex;
          flex-direction: column;
          text-align: left;
        }

        .values {
          display: flex;
          flex-direction: column;
          text-align: right;
        }
      }

      .no-investments-found-card {
        .link {
          -ms-grid-row: 3;
        }
      }
    }
  }
}
