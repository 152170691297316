.time-series-chart-wrapper {
  &:not(:last-child) {
    margin-bottom: 2em;
  }

  text.recharts-layer.recharts-cartesian-axis-tick {
    &:first-child {
      text-anchor: start;
    }

    &:last-child {
      text-anchor: end;
    }
  }
}
