.prr-page {
  display: flex;
}

.prr-total-display {
  border: 1px solid lightgray;
  border-radius: 5px;
  flex: 1 1 75%;
  margin: 15px 0 5px;
  text-align: center;
}

hr.dotted {
  border-top: 3px dotted lightgray;
}

.prr-value {
  font-size: 3em;
}

.green {
  color: green;
}

.red {
  color: red;
}
