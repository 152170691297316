.plan-overview {
  align-items: stretch;
  display: flex;
  justify-content: stretch;
}

.plan-overview-table {
  flex: 1 1 75%;
  padding-right: 10px;
}

.plan-overview-chart {
  flex: 1 1 25%;
  padding-left: 10px;
}

.balances-by-accounts-table {
  margin-bottom: 40px;
}

.disclaimer {
  margin-top: 40px;
}

.accordion-title {
  color: #035fa4;
  font-weight: bold;
}

.button-container {
  display: inline-block;
  top: -30px;
  position: relative;
  margin-left: 200px;
}

.button-container button {
  margin-left: 20px;
}
