.performance-table-filters {
  .filters {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  > * {
    margin-top: 12px;
  }

  .filter-label {
    font-weight: bold;
  }

  .btn-group {
    margin-right: 24px;

    &.account-select-menu {
      margin-top: 0;
    }
  }

  .single-account-render {
    padding-left: 15px;
    margin-top: 0;
  }

  > .filters > span {
    position: relative;
    vertical-align: middle;
  }
}
