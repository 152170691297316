.pds-segmentedControl {
  background: #e8e8e8;
  border-radius: 20px;
  position: relative;
  display: table;
  height: 34px;
  line-height: 1.75;
}

.pds-segmentedControl:focus-within {
  box-shadow: 0 0 5px #0061a0;
}

.pds-segmentedControl input {
  position: absolute;
  opacity: 0;
}

.pds-segmentedControl input + label {
  position: relative;
  color: #0061a0;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  font-family: "FS Elliot Web Bold", "FS Elliot Web Regular", Arial, arial,
    sans-serif;
  z-index: 2;
  transition: color 0.5s cubic-bezier(0.8, 0, 0.33, 1);
}

.pds-segmentedControl input:checked + label {
  z-index: 3;
  color: #002e6d;
}
