.enrollment-page {
  background-color: #e6e6e6;
  color: black;
  font-size: 90%;
}

.text-styling {
  font-size: 1%;
  color: black;
}

#loading {
  margin: auto;
}
