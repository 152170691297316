/* autoprefixer grid: autoplace */

.account-details {
  margin-bottom: 1em;
}

.account-link {
  display: block;
  margin: 10px 0 10px 0;
}

.account-summary {
  margin-bottom: 1em;

  a {
    text-decoration: inherit;
    font-weight: normal !important;
    cursor: auto;
  }

  a.collapsed {
    .transformable {
      transform: rotate(-90deg);
    }
  }

  .orange-flag {
    color: #f15a48;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 1em;
  }

  .yellow-flag {
    color: #ff9231;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 1em;
  }

  i {
    font-size: 1.5em;
    color: #0076cf;
    transition: all 0.5s;
  }

  .well {
    .distribution-contact-info {
      margin-bottom: 0;
    }
  }

  .account-header-div {
    display: flex;
    align-items: flex-start;
  }

  .account-header {
    display: inline-block;
    font-size: 16px;
    padding-left: 1em;
    color: #464646;
  }

  .account-header-stacked-top {
    padding-top: 3em;
  }

  .account-header-stacked {
    padding-top: 1em;
    padding-bottom: 3em;
  }

  .account-header-stacked-bottom {
    padding-bottom: 3em;
  }

  .account-distribution-summary {
    font-size: 16px;
    display: grid;
    grid-template-columns: 20% 20% 20% 40%;
  }

  .account-distribution {
    display: inline-block;
    padding-right: 1em;
  }
}

.balance-grid {
  align-items: center;
  display: grid;
  font-size: 1.25em;
  grid-template-columns: 0.85fr 0.5fr 1.65fr;
  text-align: center;

  .total {
    grid-template-columns: 50% 28%;
  }

  .bold {
    font-weight: bold;
  }

  p {
    white-space: nowrap;
    margin-bottom: 0;
  }
}

.account-distribution-headers-and-table {
  margin: 20px 0 4em 0;
}

.distribution-button {
  margin: auto;
}

.distribution-grid {
  align-items: flex-start;
  display: grid;
  grid-template-columns: 30% 10% 12% 10% 10% 14% 14%;
  margin-bottom: 1em;
  margin-top: 2em;

  .distribution-grid-secondary-header {
    font-size: 1.25em;
    grid-column: 4 / span 4;
    margin-bottom: 0.5em;
  }
}

.distribution-table-cells {
  grid-template-columns: 30% 10% 12% 10% 10% 14% 14%;
  overflow: hidden;
  margin-right: 0.5em;
  word-wrap: anywhere;
}

.modified-method-comments {
  margin-bottom: 2em;

  .orange-flag {
    color: #f15a48;
    margin-right: 5px;
  }

  .yellow-flag {
    color: #ff9231;
    margin-right: 5px;
  }

  .headers {
    margin-bottom: 1em;
  }

  .comments-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 25% 9% 39% 27%;
    align-items: center;
    margin-bottom: 1em;
  }
}

.distribution-election-summary {
  .header {
    margin-top: 1.8em;
  }
}

// This is for IE specific styles
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .balance-grid {
    align-items: center;
    display: flex;
    font-size: 1.25em;
    text-align: center;

    .bold {
      margin: 0 1em 0 1em;
      font-weight: bold;
    }

    p {
      white-space: nowrap;
      margin-bottom: 0;
    }
  }

  .account-summary {
    .account-distribution {
      padding: 0;
    }
  }

  .distribution-grid {
    display: -ms-grid;
    -ms-grid-columns: 9fr 4fr 4fr 4fr 4fr 5fr;
    align-items: flex-start;
    margin-bottom: 1em;

    > * {
      display: block;
    }

    .distribution-grid-secondary-header {
      margin-bottom: 0.5em;
    }
  }

  .modified-method-comments {
    .comments-grid {
      display: -ms-grid;
      -ms-grid-columns: 1fr 1fr 1fr 1fr;
      align-items: center;
      margin-bottom: 1em;

      > * {
        display: block;
      }
    }
  }

  .distcolumn {
    float: left;
    width: 50%;
    padding: 10px;
  }

  .column-1 {
    -ms-grid-column: 1;
  }
  .column-2 {
    -ms-grid-column: 2;
  }
  .column-3 {
    -ms-grid-column: 3;
  }
  .column-4 {
    -ms-grid-column: 4;
  }
  .column-5 {
    -ms-grid-column: 5;
  }
  .column-6 {
    -ms-grid-column: 6;
  }
  .column-7 {
    -ms-grid-column: 7;
  }

  .distrow:after {
    content: "";
    display: table;
    clear: both;
  }

  .row-1 {
    -ms-grid-row: 1;
  }
  .row-2 {
    -ms-grid-row: 2;
  }

  .span-2 {
    -ms-grid-column-span: 2;
  }
  .span-3 {
    -ms-grid-column-span: 3;
  }
  .span-4 {
    -ms-grid-column-span: 4;
  }
  .span-5 {
    -ms-grid-column-span: 5;
  }
  .span-6 {
    -ms-grid-column-span: 6;
  }
}

.taxImplicationText {
  padding-top: 1em;
}
