.beneficiary-plans {
  .plan-checkbox {
    cursor: pointer;

    input {
      cursor: inherit;
      height: 0.9em;
      margin: 0;
      width: 0.9em;
    }

    label {
      align-items: center;
      cursor: inherit;
      display: flex;

      font-family: "FS Elliot Web Regular", "Arial", arial, sans-serif;
      font-size: 1.5rem;
      margin: 0;

      &.bold {
        font-family: "FS Elliot Web Bold", "FS Elliot Web Regular", "Arial",
          arial, sans-serif;
      }
    }
  }
}
