.circle-style {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid white;
  margin: 5px;
  cursor: pointer;
}

.questionmark-style {
  line-height: 1;
  font-size: 14px;
  color: white;
}

.triangle-style {
  position: absolute;
  top: -10px;
  left: calc(50% - 5px);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #464646;
}
