.open-events-header {
  background-color: #0076cf;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 8px 8px 0 0;
  padding: 1.9%;
}

.separation {
  background-color: #0076cf;
  width: 100%;
  height: 1px;
}

.box-contents {
  height: 65%;
  background-color: white;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 1.9%;
  padding-left: 3.8%;
  box-sizing: border-box;
}

.last-event {
  height: 65%;
  background-color: white;
  display: flex;
  justify-content: left;
  align-items: center;
  border-radius: 0 0 8px 8px;
  padding: 1.9%;
  padding-left: 3.8%;
  box-sizing: border-box;
}
