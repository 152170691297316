.individual-investment {
  .option-div {
    width: 49%;
    float: left;
    padding-left: 2%;
  }

  .hex-display {
    display: inline-block;
    width: 12px;
    height: 12px;
    border: 1px solid black;
    margin-right: 6px;
    margin-left: 6px;
  }
}
