@import "src/scss/colors";

.dashboard {
  .account-details {
    margin: 0;
  }

  .account-panel-heading {
    align-items: center;
    display: flex;
    justify-content: space-between;

    h2 {
      margin: 0;
    }

    .account-date {
      font-size: 0.7em;
    }
  }

  .dashboard-account-modal {
    flex: 1 0;
  }

  .detail-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    min-height: 16px;
    padding: 16px 0;
    position: relative;

    &:not(:last-child) {
      border-bottom: $light-grey 1px solid;
    }

    &:first-child {
      padding-top: 0;
    }

    a {
      color: $principal-black;
      font-family: "FS Elliot Web Regular", "Arial", arial, sans-serif !important;

      &:hover {
        text-decoration: none;
      }

      &.arrow-link {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        white-space: nowrap;

        i {
          color: $principal-dark-blue;
          margin-left: 0.25em;
        }
      }

      &.name-link {
        display: flex;
        flex: 0 0 35%;
        flex-direction: column;
        white-space: nowrap;
      }
    }

    i {
      flex: 0 0 10%;
    }

    &.clickable {
      cursor: pointer;

      &:hover {
        background-color: $light-grey;
        transition: background-color 150ms ease-in-out;
      }
    }

    &.not-clickable {
      padding-right: 15px;
    }
  }

  hr {
    margin: 0;
  }

  .balance {
    font-weight: bold;
  }

  .pre-wrap {
    white-space: pre-wrap;
  }

  .since-start-of-year {
    display: block;
  }

  .floating-button {
    bottom: 1em;
    position: fixed;
    right: 1em;
    text-align: center;
    z-index: 100;
  }

  @media screen and (min-width: 767px) {
    .extended-background {
      width: 500%;
      margin-left: -200%;
    }
  }

  .fwa-background {
    background: linear-gradient(322deg, #0091da 0%, #035fa4 100%) !important;
    padding: 60px 11%;
  }
}

@media screen and (min-width: 767px) {
  .external-container {
    overflow-x: hidden;
  }
}
