.panel {
  @media print {
    page-break-inside: avoid;

    &.hidden-print-panel {
      border: none;
      padding: 0;
      page-break-inside: unset;
    }
  }
}

.panel-heading {
  .date-display {
    font-family: "FS Elliot Web Regular", "Arial", arial, sans-serif;
    margin-left: 0.5em;
    font-size: 1em;
  }

  &.center {
    display: flex;
    justify-content: center;
  }

  &.hidden-print-panel {
    @media print {
      display: none;
    }
  }
}

.toggle-panel-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    margin-left: 1em;
    margin-bottom: 0;
  }
}

.panel-dashboard {
  background-color: #4b4687;
  background-color: #00aecb;
  background-color: #00a296;
  background-color: #4b4687;
  background: linear-gradient(322deg, #58629f 0%, #4b4687 100%) !important;
  background: linear-gradient(322deg, #6670aa 0%, #4b4687 100%) !important;
  border-radius: 6px 6px 0 0;
  color: white !important;
  padding-bottom: 20px;
}
