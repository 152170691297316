.asset-class-chart {
  height: 100%;
  text-align: center;

  .label-row {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .label-box {
    width: 11px;
    height: 11px;
    border: 1px solid black;
    margin-right: 5px;
  }

  .chart-wrapper {
    margin: 0 auto;
  }

  .labels-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .labels {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
