.investment-summary {
  display: flex;
  align-items: stretch;
  justify-content: stretch;

  .investment-summary-table {
    flex: 1 1 75%;
    padding-right: 10px;
  }

  .investment-summary-chart {
    flex: 1 1 25%;
    padding-left: 10px;
  }

  .no-investment-found {
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 3em;
  }
}
