@import "../../../scss/colors";

.dashboard-view-control {
  padding: 10px 5px;
  display: inline-block;
  flex-basis: 100%;
  align-self: center;
  border: none;
  border-bottom: 3px solid transparent;
  background: $blue-background;
  color: $principal-purple;
  cursor: pointer;
  font-family: "FS Elliot Web Bold", "FS Elliot Web Regular", "Arial", arial,
    sans-serif;

  &:hover,
  &:focus {
    background: $blue-background-hover;
    text-decoration: none;
    transition: background-color 150ms ease-in-out;
  }

  &:active {
    text-decoration: none;
    background-color: $blue-background-active;
  }

  &.active {
    border-color: $principal-blue;
  }

  &:not(:last-child) {
    margin-right: 1em;
  }
}
