@import "src/scss/animations";

.manage-investments {
  .callout-wrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
  }

  .callout-container {
    flex: 1 1 auto;
    //@include animate-in-slide-up();
  }

  .height-100 {
    height: 100%;
  }

  .info-text {
    margin-top: 1em;
    font-style: italic;
  }
}
