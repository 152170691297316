.asset-class-risk-spectrum {
  margin-bottom: 25px;
  margin-top: 12px;

  & > * {
    margin-bottom: 5px;
  }

  .width-100 {
    width: 100%;
  }
}
