.single-account-display {
  margin-right: 1em;
}

.account-select-menu {
  margin: 1em 0;
  width: 300px;

  .fa-angle-down {
    margin-left: 1em;
  }

  .selector-object {
    justify-content: space-between;
  }

  .account-id-display {
    font-style: italic;
    margin-left: 0.6em;
  }

  button {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .dropdown-title {
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .account-dropdown-header {
    cursor: default;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
  }

  .account-dropdown-header:hover {
    background-color: #fff;
  }
}

.account-list {
  cursor: pointer;
  min-width: 300px;

  .fa-check {
    color: green;
  }

  .spacer {
    width: 1em;
  }

  li:not([role="separator"]) {
    display: flex;
    padding: 0.5em 1em;
    white-space: nowrap;
  }

  li:hover {
    background-color: #f1f1f1;
  }

  li > div {
    width: 3rem;
  }
}
