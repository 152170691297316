.prr-disclaimer {
  margin-top: 40px;
}

.hidden {
  display: none;
}
.viewPaidOutAccountsButton {
  margin: 1em 0;

  .fa-angle-down {
    margin-left: 1em;
  }

  .selector-object {
    justify-content: space-between;
  }

  .account-id-display {
    font-style: italic;
    margin-left: 0.6em;
  }

  button {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .dropdown-title {
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .account-dropdown-header {
    cursor: default;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
  }

  .account-dropdown-header:hover {
    background-color: #fff;
  }
}
