.filter-investments-modal {
  .title {
    margin-top: 4px;
  }

  .filter-modal-link {
    float: right;
    text-align: end;
    padding-right: 0;
  }

  .cancel-button {
    margin-right: 10px;
  }

  .modal-link-icon {
    text-decoration: none;
    // Anytime this icon is next to a span
    span + & {
      margin-left: 0.25em;
    }
  }
}
