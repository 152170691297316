.accounts-landing {
  .account-card-group {
    display: flex;
    flex-wrap: wrap;
  }

  .account-card {
    flex: 0 50%;
    padding: 10px;
  }
}
