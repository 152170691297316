.card-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  float: right;
  position: relative;
}

.arrow {
  position: relative;
  width: 0px;
  height: 0px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid;
  transform: rotate(90deg);
}

.arrow:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -10%;
  width: 10px;
  height: 2px;
  background-color: black;
}
