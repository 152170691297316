@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(slide-in-from-bottom) {
  0% {
    top: 20px;
    @include opacity(0);
  }
  60% {
    @include opacity(1);
  }
  100% {
    top: 0;
  }
}

@mixin animate-in-slide-up {
  @include animation(slide-in-from-bottom);
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
}

.animate-in-slide-up {
  @include animate-in-slide-up();
}

@include keyframes(slide-in-from-left) {
  0% {
    left: -20px;
    @include opacity(0);
  }
  60% {
    @include opacity(1);
  }
  100% {
    left: 0;
  }
}

@mixin animate-in-slide-right {
  @include animation(slide-in-from-left);
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
}

.animate-in-slide-right {
  @include animate-in-slide-up();
}
