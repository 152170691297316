.prr-summary {
  align-items: stretch;
  display: flex;
  justify-content: stretch;

  .prr-summary-data {
    flex: 1 1 67%;
    padding-right: 10px;
  }

  .prr-helpful-hints {
    flex: 1 1 33%;
    padding-left: 10px;
  }

  .hints-content {
    text-align: left;
  }

  .no-investment-found {
    font-size: 1.2em;
    margin-bottom: 3em;
    text-align: center;
  }
}
