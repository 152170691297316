$dark-grey: rgb(153, 153, 153);
$light-grey: rgb(225, 225, 225);
$grey: rgb(200, 200, 200);
$blue: #035fa4;

.timeline-wrapper {
  cursor: default;
  user-select: none;
  width: 100%;
  box-sizing: border-box;
}
.timeline {
  font-family: "FS Elliot Web Regular", "Arial", arial, sans-serif;
  font-weight: lighter;
  width: 100%;

  .axis-group {
    stroke-width: 2;
    stroke: $light-grey;

    .label {
      stroke-width: 0;
      font-size: 12px;
    }
  }

  .tick-label {
    text-anchor: middle;
    stroke-width: 0;
    fill: $dark-grey;
  }

  .item {
    cursor: pointer;
    fill: white;
    stroke: $light-grey;
    stroke-width: 2;

    &.selected {
      stroke: $blue;
    }

    .accent {
      stroke-width: 0;
      fill: $blue;
    }

    .label {
      text-anchor: middle;
      stroke-width: 0;

      &.selected {
        fill: $blue;
      }
    }
  }
}
